export const ERROR_MESSAGES = {
  current_password_incorrect: "Your current password is incorrect.",
  team_leave_error: "We experienced an error trying to remove you from the team.",
  user_not_found: "User not found.",
  invalid_request: "Invalid request.",
  invalid_token: "This token is no longer valid or has expired.",
  invalid_email_link: "This email link is invalid or has expired.",
  problem_with_link: "There is a problem with this link",
  a_problem_occurred: "We ran into a problem",
  invalid_login_credentials: "Invalid login credentials",
  error_opening_chat: "There was a problem opening this chat.",
  application_not_found: "Application not found.",
  payment_not_found: "Payment not found.",
  candidate_not_found: "Candidate not found.",
  candidate_video_not_found: "Candidate video not found.",
  anthropic_error: 'There was an error generating a response from Anthropic',
  error_inviting_candidate: "There was a problem inviting that candidate.",
  error_reporting_candidate: "There was a problem reporting this candidate.",
  error_updating_profile: "There was a problem updating your profile information.",
  error_fetching_profile: "There was a problem fetching your profile information.",
  error_updating_company: "There was a problem updating your company information.",
  error_updating_email_preferences: "There was a problem updating your email preferences.",
  error_updating_email: "There was a problem updating your email.",
  error_updating_password: "There was a problem updating your password.",
  error_updating_application: "There was a problem updating this application.",
  error_updating_job: "There was a problem updating your job.",
  error_creating_job: "There was a problem creating your job. Please try again.",
  error_updating_video: "There was a problem updating the candidate video.",
  error_submitting_support_request: "There was a problem submitting your support request. Please try again.",
  error_submitting_sales_request: "We ran into a problem submitting your request to contact sales. Please try again.",
  file_download_error: "There was a problem downloading the file.",
  could_not_retrieve_image: "There was a problem retrieving the image.",
  employer_not_found: "Employer not found.",
  company_not_found: "Company not found.",
  chat_not_found: "Chat not found.",
  job_not_found: "Job not found.",
  job_embedding_not_found: "Job embedding not found.",
  log_not_found: "Log not found.",
  notification_not_found: "Notification not found.",
  message_not_found: "Message not found.",
  no_company_id: "Invalid request: no company id.",
  no_id: "Invalid request: no id.",
  no_body: "Invalid request: no body.",
  no_password: "Invalid request: no password.",
  no_email: "Invalid request: no email.",
  error_sending_message: "There was a problem sending your message.",
  error_inviting_to_chat: "There was a problem inviting those team members to the chat.",
  error_initialising_payment: "There was a problem initialising your payment.",
  payment_unsuccessful: "Your payment was unsuccessful. Please try again.",
  error_deleting_job: "There was a problem deleting your job.",
  error_archiving_job: "There was a problem archiving your job.",
  error_extending_job: "There was a problem extending your job.",
  error_deleting_application: "There was a problem deleting this application.",
  error_retrieving_profile: "There was a problem retrieving that candidate's profile.",
  error_creating_your_user: "There was a problem creating your user.",
  error_submitting_contact_enquiry: "There was a problem submitting your contact enquiry.",
  error_cancelling_invited: "There was a problem cancelling that invitation.",
  error_removing_member: "There was a problem removing that member.",
  error_updating_member_role: "There was a problem updating that member's role.",
  error_inviting_member: "There was a problem inviting the user.",
  error_deleting_notifications: "There was a problem deleting your notifications.",
  error_marking_notifications_as_read: "There was a problem marking some notifications as read.",
  error_deleting_notification: "There was a problem deleting that notification.",
  error_deleting_company: "There was a problem deleting your company.",
  error_deleting_account: "There was a problem deleting your account.",
  error_deleting_chat: "There was a problem deleting that chat.",
  error_inviting_candidates: "There was a problem inviting those candidates to apply.",
  error_actioning_reference: "There was a problem updating this candidate's reference.",
  error_adding_industry_position: "There was a problem adding your industry position.",
  error_retrieving_identities: "There was a problem retrieving Oauth indentities for this account.",
  no_identity_found: "We could not find the matching Oauth identity for this account.",
  email_not_confirmed: "Email not confirmed.",
  not_enough_credits: "Not enough credits.",
  has_app_account: "That email is already associated with a user on the Hirable App.",
  user_already_exists: "A user with that email already exists.",
  user_already_registered: "A user with this email address has already been registered",
  invite_already_pending: "This user already has an invite pending.",
  no_recaptcha_token_found: "No recaptcha token was found in the request.",
  recaptcha_verify_error: "We were unable to verify your submission. Please try again later.",
  recaptcha_score_too_low: "We have detected unusual activities from your browser. Please try again later.",
  sbwh_signature_not_found: "Supabase Webhook Guard: Signature not found in the request headers.",
  sbwh_body_not_found: "Supabase Webhook Guard: Request body not found.",
  sbwh_invalid_authentication: "Supabase Webhook Guard: Invalid authentication.",
  algolia_no_authorisation_header: "Algolia API Guard: No authorisation header found.",
  algolia_incorrect_credentials: "Algolia API Guard: Incorrect credentials.",
  sending_messages_too_quickly: "You are sending messages too quickly. Please slow down.",
  too_many_requests: "You were sending too many requests. Please try again in a moment.",
  prompt_too_long: "Your prompt is more than the allowed character limit.",
  error_generating_response: "There was a problem generating a response. Please try again later.",
  not_authorised: "You are not authorised to perform this action.",
  promo_already_claimed: "You have already claimed your free credit.",
};
